import {Inject, Injectable} from '@angular/core';
import {ShepherdService} from 'angular-shepherd';

@Injectable({providedIn: 'root'})
export class FivefTourService {
  public showAutomated = false;

  constructor(@Inject(ShepherdService) private shepherdService: ShepherdService) {
  }


  startTourDashboard() {
    this.shepherdService.defaultStepOptions = {
      classes: 'mt-2',
      arrow: true,
      scrollTo: {
        behavior: 'smooth',
      },

      cancelIcon: {
        enabled: true
      }
    };
    this.shepherdService.modal = true;
    this.shepherdService.addSteps([
      {
        id: 'introduction',
        buttons: [
          {
            classes: 'mdc-button mdc-button--raised mat-mdc-raised-button mat-primary mat-mdc-button-base',
            text: 'Start Tour',
            action() {
              this.next()
            }
          }
        ],
        classes: 'fivef-shepherd-header',
        title: '<img src="./assets/img/5fsoftware-logo.png" style="width: 300px"> <br>  <h3>Welcome Aboard!</h3>  ',
        text: ['This tour will guide you through the key features and functionalities of this page, to ensure you experience is as pleasant as possible.'],
      },
      {
        id: 'navbar',
        attachTo: {
          element: '.fivef-page-header--container',
          on: 'bottom',
        },
        buttons: [
          {
            classes: 'mdc-button mdc-button--raised mat-mdc-raised-button mat-primary mat-mdc-button-base',
            text: 'Next',
            action() {
              this.next()
            }
          }
        ],
        title: 'Top Navbar',
        text: ['This navigation bar allows you to search for workflows, check your inbox, change the language of the platform and go to your settings.'],
      },
      {
        id: 'sidebar',
        attachTo: {
          element: '.fivef-main-app--navigation-wrapper',
          on: 'right',
        },
        classes: 'ml-2',
        buttons: [
          {
            classes: 'mdc-button mat-mdc-button mat-unthemed mat-mdc-button-base',
            text: 'Back',
            action() {
              this.back()
            }
          },
          {
            classes: 'mdc-button mdc-button--raised mat-mdc-raised-button mat-primary mat-mdc-button-base',
            text: 'Next',
            action() {
              this.next()
            }
          }
        ],
        title: 'Sidebar',
        text: ['The sidebar allows you to access different parts of the platform easily, such as this dashboard, the All Projects page, your Tasks, the 5F Blogs and the Label Search.'],
      },
      {
        id: 'partnerlink',
        attachTo: {
          element: '.fivef-partner-link-panel',
          on: 'bottom',
        },
        buttons: [
          {
            classes: 'mdc-button mat-mdc-button mat-unthemed mat-mdc-button-base',
            text: 'Back',
            action() {
              this.back()
            }
          },
          {
            classes: 'mdc-button mdc-button--raised mat-mdc-raised-button mat-primary mat-mdc-button-base',
            text: 'Next',
            action() {
              this.next()
            }
          }
        ],
        title: 'Partnerlinks',
        text: ['The partnerlinks are created by the office for the clients, and are a convenient shortcut to access external resources.'],
      },
      {
        id: 'notifications',
        attachTo: {
          element: '.fivef-updates-panel--updates',
          on: 'top',
        },
        buttons: [
          {
            classes: 'mdc-button mat-mdc-button mat-unthemed mat-mdc-button-base',
            text: 'Back',
            action() {
              this.back()
            }
          },
          {
            classes: 'mdc-button mdc-button--raised mat-mdc-raised-button mat-primary mat-mdc-button-base',
            text: 'Next',
            action() {
              this.next()
            }
          }
        ],
        title: 'New Information',
        text: ['Here you will have an overview of the updates inside projects, messages, overdue events, and uploaded documents.'],
      },
      {
        id: 'favoriteProjects',
        attachTo: {
          element: '.fivef-favorite-panel',
          on: 'bottom',
        },
        buttons: [
          {
            classes: 'mdc-button mat-mdc-button mat-unthemed mat-mdc-button-base',
            text: 'Back',
            action() {
              this.back()
            }
          },
          {
            classes: 'mdc-button mdc-button--raised mat-mdc-raised-button mat-primary mat-mdc-button-base',
            text: 'Next',
            action() {
              this.next()
            }
          }
        ],
        title: 'Your Current Project',
        text: ['By default, accepting invitation to a workflow will mark it as “Favorite” and display it here for convenient access.', ' You can unmark a project as Favorite from inside the Project Room.'],
      },
      {
        id: 'createWorkflow',
        attachTo: {
          element: '.quickstart-list',
          on: 'bottom-start',
        },
        classes: 'mt-2',
        buttons: [

          {
            classes: 'mdc-button mat-mdc-button mat-unthemed mat-mdc-button-base',
            text: 'Back',
            action() {
              this.back()
            }
          },
          {
            classes: 'mdc-button mdc-button--raised mat-mdc-raised-button mat-primary mat-mdc-button-base',
            text: 'Next',
            action() {
              this.next()
            }
          }
        ],
        title: 'Create Workflow',
        text: ['This floating button allows you to create projects and other types of workflos by yourself.'],
      },
      {
        id: 'duedates',
        attachTo: {
          element: '.fivef-activities-overdue-events',
          on: 'right',
        },
        classes: 'ml-4',
        buttons: [
          {
            classes: 'mdc-button mat-mdc-button mat-unthemed mat-mdc-button-base',
            text: 'Exit',
            action() {
              this.complete()
            }
          },
        ],
        title: 'Your Due Dates',
        text: ['In this section, you can see tasks with upcoming due dates and overdue dates.', ' You can access the respective workflows from this list, and see upcoming due dates by clicking the calendar.'],
      }
    ]);


    // Start the tour
    this.shepherdService.start();
  }

  startReleaseInfo() {
    this.shepherdService.defaultStepOptions = {
      cancelIcon: {
        enabled: true
      }
    };
    this.shepherdService.modal = true;
    this.shepherdService.addSteps([
      {
        id: 'introduction',
        buttons: [
          {
            classes: 'mdc-button mdc-button--raised mat-mdc-raised-button mat-primary mat-mdc-button-base',
            text: 'Show New Features',
            action() {
              this.complete()
            }
          }
        ],
        classes: 'fivef-shepherd-header fivef-Release-Tour',
        title: '<h2 class="text-2xl  font-weight-bolder mb-1">🚀 New Platform Update - v2.1.0</h2>',
        text: [
          '    <div class="p-4 pt-0">\n' +
          '        <p class="text-gray-600">We’ve made some exciting improvements and squashed some bugs to enhance your experience!</p>\n' +
          '        \n' +
          '        <div class="mt-2">\n' +
          '            <h3 class="text-lg font-weight-bold" style="color: green">✨ New Features</h3>\n' +
          '            <ul class="list-disc p-2">\n' +
          '                <li>Dark mode support for better readability at night.</li>\n' +
          '                <li>Improved dashboard performance and analytics.</li>\n' +
          '                <li>New customizable notification settings.</li>\n' +
          '            </ul>\n' +
          '        </div>\n' +
          '        \n' +
          '        <div class="mt-2">\n' +
          '            <h3 class="text-lg font-weight-bold text-danger">🐛 Bug Fixes</h3>\n' +
          '            <ul class="list-disc p-2">\n' +
          '                <li>Fixed login issues affecting some users.</li>\n' +
          '                <li>Resolved slow loading time on reports page.</li>\n' +
          '                <li>Addressed minor UI glitches for better usability.</li>\n' +
          '            </ul>\n' +
          '        </div>\n' +
          '        \n' +
          '    </div>'
        ],
      }

    ]);


    // Start the tour
    this.shepherdService.start();
  }
}
