<ng-container *ngIf="isAuthorized">
  <button mat-mini-fab
          color="primary"
          class="mr-2"
          (click)="showReleaseInfo()"
          [matTooltip]="'Info Release'"
          [matTooltipPosition]="'above'">
    <mat-icon>info</mat-icon>
  </button>

  <button mat-mini-fab
          color="primary"
          [matMenuTriggerFor]="quickStartMenu"
          [matTooltip]="'GENERAL.CREATE_WORKFLOW_ACTION' | translate"
          [matTooltipPosition]="'above'">
    <mat-icon>add</mat-icon>
  </button>
  <mat-menu #quickStartMenu="matMenu"
            yPosition="below"
            xPosition="before"
            class="fivef-menu">
    <fivef-menu-item [icon]="'process'"
                     [title]="'New Project Automated'"
                     [subtitle]="'Create a basic project with a specified title'"
                     (click)="navigateToAutomatedProject(['/projects/new'])"></fivef-menu-item>
    <dvtx-project-quickstarter></dvtx-project-quickstarter>
    <dvtx-project-quickstarter-from-template></dvtx-project-quickstarter-from-template>
    <dvtx-project-room-quickstarter></dvtx-project-room-quickstarter>
    <dvtx-quickshare-quickstarter></dvtx-quickshare-quickstarter>
    <fivef-signature-selection [action]="signatureButtonType"></fivef-signature-selection>
    <dvtx-collector-quickstarter></dvtx-collector-quickstarter>
  </mat-menu>
</ng-container>
