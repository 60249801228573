import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {distinctUntilKeyChanged, filter, takeUntil} from 'rxjs/operators';
import {User} from '../../../../+store/user/user';
import {DocumentSignatureSelectionMenuViewType} from '../../../../lib/fivef-ui/sign/fivef-signature-selection/fivef-signature-selection.component';
import {Feature} from 'app/+store/feature/feature';
import {FeatureSelectors} from 'app/+store/feature';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';
import {Router} from '@angular/router';
import {FivefTourService} from '../../../../services/fivef-tour.service';

@Component({
  selector: 'dvtx-quickstart-bar',
  templateUrl: './quickstart-bar.component.html',
  styleUrls: ['./quickstart-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuickstartBarComponent implements OnInit, OnDestroy {
  private onDestroy: Subject<void> = new Subject<void>();

  public readonly signatureButtonType = DocumentSignatureSelectionMenuViewType.Quickstart;

  public isAuthorized = false;
  public featureSet$: Observable<Feature>;

  constructor(private store: Store<AppState>,
              private cdr: ChangeDetectorRef,
              private router: Router,
              private fivefTourSvc: FivefTourService) {
  }

  ngOnInit() {
    this.store.select('currentUser')
      .pipe(filter(u => !!u), distinctUntilKeyChanged('uid'), takeUntil(this.onDestroy))
      .subscribe((user: User) => {
        this.isAuthorized = !!user;
        this.cdr.detectChanges();
      });

    this.featureSet$ = this.store.select(FeatureSelectors.getCurrentFeatureSet);
    this.fivefTourSvc.startTourDashboard();
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }


  navigateToAutomatedProject(link) {
    this.fivefTourSvc.showAutomated = true;
    this.router.navigate(link);
  }

  showReleaseInfo() {
    this.fivefTourSvc.startReleaseInfo()
  }
}
