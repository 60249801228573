import {Component, Input, OnInit} from '@angular/core';
import {Organization} from 'app/+store/organization/organization';
import {MatDialog} from '@angular/material/dialog';
import {AppState} from 'app/app.state';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {OrganizationSelectors} from 'app/+store/organization';
import {ProcessProfile} from 'app/+store/process/process.interface';
import {Observable} from 'rxjs/internal/Observable';
import {FivefTourService} from '../../../../services/fivef-tour.service';

/**
 * Project Workflow quickstarter.
 *
 * See quickstart bar for a usage example.
 * Advanced usage example:
 * <dvtx-project-quickstarter *ngIf="(featureSet$ | async)?.hasBookman"
 *                            [title]="'FIBU.FIBU_WORKFLOWS_PROJECT'"
 *                            [subTitle]="'FIBU.FIBU_WORKFLOWS_PROJECT_SUBTITLE'"
 *                            [link]="'/projects/new'"
 *                            [icon]="'fibu_workflow'"
 *                            [profile]="ProcessProfile.Fibu"></dvtx-project-quickstarter>
 */
@Component({
  selector: 'dvtx-project-quickstarter',
  templateUrl: './project-quickstarter.component.html',
  styleUrls: ['./project-quickstarter.component.scss']
})
export class ProjectQuickstarterComponent implements OnInit {
  organizations: Observable<Organization[]>;
  organizationsCount: number;
  ProcessProfile = ProcessProfile;

  @Input() title = 'PROJECT.NEW_PROJECT';
  @Input() subTitle = 'PROJECT.NEW_PROJECT_SUBTITLE';
  @Input() link = '/projects/new';
  @Input() profile: ProcessProfile = ProcessProfile.StandardProfile;
  @Input() icon = 'process';

  constructor(
    public dialog: MatDialog,
    private _store: Store<AppState>,
    private _translateSvc: TranslateService,
    private router: Router,
    private fivefTourSvc: FivefTourService
  ) {
  }

  ngOnInit() {
    this.organizations = this._store.select(
      OrganizationSelectors.getAllOrganizations
    );
    this.organizations.subscribe(organizations => {
      this.organizationsCount = organizations.length;
    });
  }

  redirectToLink(link) {
    this.fivefTourSvc.showAutomated = false;
    this.router.navigate(link, {queryParams: {profile: this.profile}});
  }
}
